import { callWithNuxt, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async(to) => {
    // It's important to do this as early as possible
    const nuxtApp = useNuxtApp()

    const {status} = useAuth();
    const storeConfig = useStoreConfig()
    const { accessType } = storeToRefs(storeConfig)
    const redirectToAfterSignedIn = useCookie('redirectTo')

    if (unref(accessType) === 'b2b' && to.path.startsWith('/register')) {
        return callWithNuxt(nuxtApp, () => navigateTo(redirectToAfterSignedIn.value))
    }

    if (status?.value === 'authenticated') {
        /**
         * We cannot directly call and/or return `signIn` here as `signIn` uses async composables under the hood, leading to "nuxt instance undefined errors", see https://github.com/nuxt/framework/issues/5740#issuecomment-1229197529
         *
         * So to avoid calling it, we call it via `callWithNuxt`.
         */
        return callWithNuxt(nuxtApp, () => navigateTo(redirectToAfterSignedIn.value))
    }
})
